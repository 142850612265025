import { Book } from "../types"
import { Saga } from "../types"

export const books: Book[] = [
  {
    id: "the_colour_of_magic",
    title: "The Colour of Magic",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 1,
  },
  {
    id: "the_light_fantastic",
    title: "The Light Fantastic",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 2,
  },
  {
    id: "equal_rites",
    title: "Equal Rites",
    saga: Saga.THE_WITCHES,
    publicationOrder: 3,
  },
  {
    id: "mort",
    title: "Mort",
    saga: Saga.DEATH,
    publicationOrder: 4,
  },
  {
    id: "sourcery",
    title: "Sourcery",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 5,
  },
  {
    id: "wyrd_sisters",
    title: "Wyrd Sisters",
    saga: Saga.THE_WITCHES,
    publicationOrder: 6,
  },
  {
    id: "pyramids",
    title: "Pyramids",
    saga: Saga.STANDALONES,
    publicationOrder: 7,
  },
  {
    id: "guards_guards",
    title: "Guards! Guards!",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 8,
  },
  {
    id: "eric",
    title: "Eric",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 9,
  },
  {
    id: "moving_pictures",
    title: "Moving Pictures",
    saga: Saga.STANDALONES,
    publicationOrder: 10,
  },
  {
    id: "reaper_man",
    title: "Reaper Man",
    saga: Saga.DEATH,
    publicationOrder: 11,
  },
  {
    id: "witches_abroad",
    title: "Witches Abroad",
    saga: Saga.THE_WITCHES,
    publicationOrder: 12,
  },
  {
    id: "small_gods",
    title: "Small Gods",
    saga: Saga.STANDALONES,
    publicationOrder: 13,
  },
  {
    id: "lords_and_ladies",
    title: "Lords and Ladies",
    saga: Saga.THE_WITCHES,
    publicationOrder: 14,
  },
  {
    id: "men_at_arms",
    title: "Men At Arms",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 15,
  },
  {
    id: "soul_music",
    title: "Soul Music",
    saga: Saga.DEATH,
    publicationOrder: 16,
  },
  {
    id: "interesting_times",
    title: "Interesting Times",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 17,
  },
  {
    id: "maskerade",
    title: "Maskerade",
    saga: Saga.THE_WITCHES,
    publicationOrder: 18,
  },
  {
    id: "feet_of_clay",
    title: "Feet of Clay",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 19,
  },
  {
    id: "hogfather",
    title: "Hogfather",
    saga: Saga.DEATH,
    publicationOrder: 20,
  },
  {
    id: "jingo",
    title: "Jingo",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 21,
  },
  {
    id: "the_last_continent",
    title: "The Last Continent",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 22,
  },
  {
    id: "carpe_jugulum",
    title: "Carpe Jugulum",
    saga: Saga.THE_WITCHES,
    publicationOrder: 23,
  },
  {
    id: "the_fifth_elephant",
    title: "The Fifth Elephant",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 24,
  },
  {
    id: "the_truth",
    title: "The Truth",
    saga: Saga.STANDALONES,
    publicationOrder: 25,
  },
  {
    id: "thief_of_time",
    title: "Thief of Time",
    saga: Saga.DEATH,
    publicationOrder: 26,
  },
  {
    id: "the_last_hero",
    title: "The Last Hero",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 27,
  },
  {
    id: "the_amazing_maurice_and_his_educated_rodents",
    title: "The Amazing Maurice and His Educated Rodents",
    saga: Saga.STANDALONES,
    publicationOrder: 28,
  },
  {
    id: "night_watch",
    title: "Night Watch",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 29,
  },
  {
    id: "the_wee_free_men",
    title: "The Wee Free Men",
    saga: Saga.TIFFANY_ACHING_AND_NAC_MAC_FEEGLEs,
    publicationOrder: 30,
  },
  {
    id: "monstrous_regiment",
    title: "Monstrous Regiment",
    saga: Saga.STANDALONES,
    publicationOrder: 31,
  },
  {
    id: "a_hat_full_of_sky",
    title: "A Hat Full Of Sky",
    saga: Saga.TIFFANY_ACHING_AND_NAC_MAC_FEEGLEs,
    publicationOrder: 32,
  },
  {
    id: "going_postal",
    title: "Going Postal",
    saga: Saga.MOIST_VON_LIPWIG,
    publicationOrder: 33,
  },
  {
    id: "thud",
    title: "Thud!",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 34,
  },
  {
    id: "wintersmith",
    title: "Wintersmith",
    saga: Saga.TIFFANY_ACHING_AND_NAC_MAC_FEEGLEs,
    publicationOrder: 35,
  },
  {
    id: "making_money",
    title: "Making Money",
    saga: Saga.MOIST_VON_LIPWIG,
    publicationOrder: 36,
  },
  {
    id: "unseen_academicals",
    title: "Unseen Academicals",
    saga: Saga.THE_WIZARDS_RINCEWIND,
    publicationOrder: 37,
  },
  {
    id: "i_shall_wear_midnight",
    title: "I Shall Wear Midnight",
    saga: Saga.TIFFANY_ACHING_AND_NAC_MAC_FEEGLEs,
    publicationOrder: 38,
  },
  {
    id: "snuff",
    title: "Snuff",
    saga: Saga.THE_CITY_WATCH,
    publicationOrder: 39,
  },
  {
    id: "raising_steam",
    title: "Raising Steam",
    saga: Saga.MOIST_VON_LIPWIG,
    publicationOrder: 40,
  },
  {
    id: "the_shepherds_crown",
    title: "The Shepherd's Crown",
    saga: Saga.TIFFANY_ACHING_AND_NAC_MAC_FEEGLEs,
    publicationOrder: 41,
  },
]
